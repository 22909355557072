import * as React from "react"
import Helmet from "react-helmet"
import Layout from "../common/layout"
import Seo from "../common/seo"
import "tachyons"

const Privacy = () => {
  return (
    <Layout>
      <Seo title="Privacy Policy" description="" />
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <div className="bg-washed-red pv5 flex flex-column items-center">
        <h1 className="db f1 display fw1">Privacy Policy</h1>
        <p className="db sans-serif f5 tracked ttu tc">
          Ingangsdatum: Januari 1, 2019
        </p>
      </div>
      <div className="lh-copy f4 serif mw8 center">
        <h1 className="sans-serif">Flex and Relax</h1>
        <p>
          {" "}
          Gevestigd aan Stakman Bossestraat 2B, 1781 SX Den Helder, is
          verantwoordelijk voor de verwerking van persoonsgegevens zoals
          weergegeven in deze privacyverklaring.
        </p>
        <h2 className="sans-serif">Contactgegevens:</h2>
        <p>
          https://flexandrelax.nl Stakman Bossestraat 2B, 1781 SX Den Helder 06
          13435896
        </p>
        <h2 className="sans-serif">Persoonsgegevens die wij verwerken</h2>
        <p>
          Flex and Relax verwerkt geen persoonsgegevens omdat op onze site geen
          persoonsgegevens achter gelaten kunnen worden.
        </p>
        <h2 className="sans-serif">
          Met welk doel en op basis van welke grondslag wij persoonsgegevens
          verwerken
        </h2>
        <p>
          Flex and Relax verwerkt uw persoonsgegevens voor de volgende doelen:
        </p>
        <div>- Verzenden van onze nieuwsbrief</div>
        <div>
          - Flex and Relax analyseert uw gedrag op de website om daarmee de
          website te verbeteren en het aanbod van producten en diensten af te
          stemmen op uw voorkeuren.
        </div>
        <h2 className="sans-serif">
          Cookies, of vergelijkbare technieken, die wij gebruiken
        </h2>
        <p>
          Flex and Relax gebruikt functionele, analytische en tracking cookies.
          Een cookie is een klein tekstbestand dat bij het eerste bezoek aan
          deze website wordt opgeslagen in de browser van uw computer, tablet of
          smartphone. Flex and Relax gebruikt cookies met een puur technische
          functionaliteit. Deze zorgen ervoor dat de website naar behoren werkt
          en dat bijvoorbeeld uw voorkeursinstellingen onthouden worden. Deze
          cookies worden ook gebruikt om de website goed te laten werken en deze
          te kunnen optimaliseren. U kunt zich afmelden voor cookies door uw
          internetbrowser zo in te stellen dat deze geen cookies meer opslaat.
          Daarnaast kunt u ook alle informatie die eerder is opgeslagen via de
          instellingen van uw browser verwijderen.
        </p>
        <h2 className="sans-serif">Hoe wij persoonsgegevens beveiligen</h2>
        <p>
          Flex and Relax neemt de bescherming van uw gegevens serieus en neemt
          passende maatregelen om misbruik, verlies, onbevoegde toegang,
          ongewenste openbaarmaking en ongeoorloofde wijziging tegen te gaan.
          Als u de indruk heeft dat uw gegevens niet goed beveiligd zijn of er
          aanwijzingen zijn van misbruik, neem dan contact op met onze
          klantenservice of via lindavanderwal@quicknet.nl
        </p>
      </div>
    </Layout>
  )
}

export default Privacy
